var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    attrs: {
      id: "content"
    }
  }, [_vm._m(0), _c("div", {
    staticClass: "companyBlock"
  }, [_c("div", {
    staticClass: "companyBlock-about active"
  }, [_c("div", {
    staticClass: "companyBlock-hd"
  }, [_vm.language == 1 ? _c("h2", [_vm._v("诺辉健康科技有限公司")]) : _vm._e(), _vm.language == 2 ? _c("h2", [_vm._v("New Horizon Health")]) : _vm._e(), _vm.language == 3 ? _c("h2", [_vm._v("諾輝健康科技有限公司")]) : _vm._e()]), _c("div", {
    staticClass: "contactBox clearfix"
  }, [_c("baidu-map", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.current != 4,
      expression: "current!=4"
    }],
    staticClass: "map",
    attrs: {
      id: "map"
    },
    on: {
      ready: _vm.init
    }
  }), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.current == 4,
      expression: "current==4"
    }],
    staticClass: "shjgImgBox"
  }, [_c("img", {
    staticClass: "shjgImg",
    attrs: {
      src: require("../assets/shjg.jpg")
    }
  })]), _c("div", {
    staticClass: "col-main"
  }, [_c("div", {
    staticClass: "contact_right"
  }, [_c("div", {
    staticClass: "contact_right-top j_Mouseover"
  }, [_vm._l(_vm.allData, function (item, index) {
    return _vm.language == 1 ? _c("a", {
      class: {
        active: index == _vm.current
      },
      attrs: {
        href: "#",
        "data-lng": item.contactA,
        "data-lat": item.contactB,
        "data-name": item.contactName
      },
      on: {
        mouseover: function ($event) {
          return _vm.showDt(index, $event);
        }
      }
    }, [_vm._v(_vm._s(item.contactType))]) : _vm._e();
  }), _vm._l(_vm.allData, function (item, index) {
    return _vm.language == 2 ? _c("a", {
      class: {
        active: index == _vm.current
      },
      attrs: {
        href: "#",
        "data-lng": item.contactA,
        "data-lat": item.contactB,
        "data-name": item.contactName
      },
      on: {
        mouseover: function ($event) {
          return _vm.showDt(index, $event);
        }
      }
    }, [_vm._v(_vm._s(item.contactTypeEn))]) : _vm._e();
  }), _vm._l(_vm.allData, function (item, index) {
    return _vm.language == 3 ? _c("a", {
      class: {
        active: index == _vm.current
      },
      attrs: {
        href: "#",
        "data-lng": item.contactA,
        "data-lat": item.contactB,
        "data-name": item.contactName
      },
      on: {
        mouseover: function ($event) {
          return _vm.showDt(index, $event);
        }
      }
    }, [_vm._v(_vm._s(item.contactTypeTr))]) : _vm._e();
  })], 2), _c("div", {
    staticClass: "contact_right_addre j_Mouseovershow"
  }, [_c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.current == 0,
      expression: "current==0"
    }]
  }, [_c("ul", [_c("li", [_c("em", {
    staticClass: "el-icon-phone"
  }), _vm.language == 1 ? _c("span", [_vm._v("电话：" + _vm._s(_vm.hzList.contactTel)), _c("br"), _vm._v("传真：" + _vm._s(_vm.hzList.contactCz))]) : _vm._e(), _vm.language == 2 ? _c("span", [_vm._v("Tel：" + _vm._s(_vm.hzList.contactTel)), _c("br"), _vm._v("Fax：" + _vm._s(_vm.hzList.contactCz))]) : _vm._e(), _vm.language == 3 ? _c("span", [_vm._v("電話：" + _vm._s(_vm.hzList.contactTel)), _c("br"), _vm._v("傳真：" + _vm._s(_vm.hzList.contactCz))]) : _vm._e()]), _c("li", [_c("em", {
    staticClass: "el-icon-copy-document"
  }), _vm.language == 1 ? _c("span", [_vm._v("邮箱：" + _vm._s(_vm.hzList.contactEmail)), _c("br"), _vm._v("邮编：" + _vm._s(_vm.hzList.contactYb))]) : _vm._e(), _vm.language == 2 ? _c("span", [_vm._v("Email：" + _vm._s(_vm.hzList.contactEmail)), _c("br"), _vm._v("Zip：" + _vm._s(_vm.hzList.contactYb))]) : _vm._e(), _vm.language == 3 ? _c("span", [_vm._v("郵箱：" + _vm._s(_vm.hzList.contactEmail)), _c("br"), _vm._v("郵編：" + _vm._s(_vm.hzList.contactYb))]) : _vm._e()]), _c("li", [_c("em", {
    staticClass: "el-icon-map-location"
  }), _vm.language == 1 ? _c("span", [_vm._v("地址：" + _vm._s(_vm.hzList.contactAddress))]) : _vm._e(), _vm.language == 2 ? _c("span", [_vm._v("Address：" + _vm._s(_vm.hzList.contactAddressEn))]) : _vm._e(), _vm.language == 3 ? _c("span", [_vm._v("地址：" + _vm._s(_vm.hzList.contactAddressTr))]) : _vm._e()])])]), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.current == 1,
      expression: "current==1"
    }]
  }, [_c("ul", [_c("li", [_c("em", {
    staticClass: "el-icon-phone"
  }), _vm.language == 1 ? _c("span", [_vm._v("电话：" + _vm._s(_vm.bjList.contactTel)), _c("br"), _vm._v("传真：" + _vm._s(_vm.bjList.contactCz))]) : _vm._e(), _vm.language == 2 ? _c("span", [_vm._v("Tel：" + _vm._s(_vm.bjList.contactTel)), _c("br"), _vm._v("Fax：" + _vm._s(_vm.bjList.contactCz))]) : _vm._e(), _vm.language == 3 ? _c("span", [_vm._v("電話：" + _vm._s(_vm.bjList.contactTel)), _c("br"), _vm._v("傳真：" + _vm._s(_vm.bjList.contactCz))]) : _vm._e()]), _c("li", [_c("em", {
    staticClass: "el-icon-copy-document"
  }), _vm.language == 1 ? _c("span", [_vm._v("邮箱：" + _vm._s(_vm.bjList.contactEmail)), _c("br"), _vm._v("邮编：" + _vm._s(_vm.bjList.contactYb))]) : _vm._e(), _vm.language == 2 ? _c("span", [_vm._v("Email：" + _vm._s(_vm.bjList.contactEmail)), _c("br"), _vm._v("Zip：" + _vm._s(_vm.bjList.contactYb))]) : _vm._e(), _vm.language == 3 ? _c("span", [_vm._v("郵箱：" + _vm._s(_vm.bjList.contactEmail)), _c("br"), _vm._v("郵編：" + _vm._s(_vm.bjList.contactYb))]) : _vm._e()]), _c("li", [_c("em", {
    staticClass: "el-icon-map-location"
  }), _vm.language == 1 ? _c("span", [_vm._v("地址：" + _vm._s(_vm.bjList.contactAddress))]) : _vm._e(), _vm.language == 2 ? _c("span", [_vm._v("Address：" + _vm._s(_vm.bjList.contactAddressEn))]) : _vm._e(), _vm.language == 3 ? _c("span", [_vm._v("地址：" + _vm._s(_vm.bjList.contactAddressTr))]) : _vm._e()])])]), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.current == 2,
      expression: "current==2"
    }]
  }, [_c("ul", [_c("li", [_c("em", {
    staticClass: "el-icon-phone"
  }), _vm.language == 1 ? _c("span", [_vm._v("电话：" + _vm._s(_vm.gzList.contactTel)), _c("br"), _vm._v("传真：" + _vm._s(_vm.gzList.contactCz))]) : _vm._e(), _vm.language == 2 ? _c("span", [_vm._v("Tel：" + _vm._s(_vm.gzList.contactTel)), _c("br"), _vm._v("Fax：" + _vm._s(_vm.gzList.contactCz))]) : _vm._e(), _vm.language == 3 ? _c("span", [_vm._v("電話：" + _vm._s(_vm.gzList.contactTel)), _c("br"), _vm._v("傳真：" + _vm._s(_vm.gzList.contactCz))]) : _vm._e()]), _c("li", [_c("em", {
    staticClass: "el-icon-copy-document"
  }), _vm.language == 1 ? _c("span", [_vm._v("邮箱：" + _vm._s(_vm.gzList.contactEmail)), _c("br"), _vm._v("邮编：" + _vm._s(_vm.gzList.contactYb))]) : _vm._e(), _vm.language == 2 ? _c("span", [_vm._v("Email：" + _vm._s(_vm.gzList.contactEmail)), _c("br"), _vm._v("Zip：" + _vm._s(_vm.gzList.contactYb))]) : _vm._e(), _vm.language == 3 ? _c("span", [_vm._v("郵箱：" + _vm._s(_vm.gzList.contactEmail)), _c("br"), _vm._v("郵編：" + _vm._s(_vm.gzList.contactYb))]) : _vm._e()]), _c("li", [_c("em", {
    staticClass: "el-icon-map-location"
  }), _vm.language == 1 ? _c("span", [_vm._v("地址：" + _vm._s(_vm.gzList.contactAddress))]) : _vm._e(), _vm.language == 2 ? _c("span", [_vm._v("Address：" + _vm._s(_vm.gzList.contactAddressEn))]) : _vm._e(), _vm.language == 3 ? _c("span", [_vm._v("地址：" + _vm._s(_vm.gzList.contactAddressTr))]) : _vm._e()])])]), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.current == 3,
      expression: "current==3"
    }]
  }, [_c("ul", {
    staticClass: "wework"
  }, _vm._l(_vm.officeList, function (item, index) {
    return _c("li", {
      class: {
        active: index == _vm.currentLi
      },
      attrs: {
        "data-lng": item.contactA,
        "data-lat": item.contactA,
        "data-name": item.contactName
      },
      on: {
        click: function ($event) {
          return _vm.showLi(index, $event);
        }
      }
    }, [_c("em", {
      staticClass: "el-icon-map-location"
    }), _vm.language == 1 ? _c("span", [_vm._v(_vm._s(item.contactCity)), _c("br"), _vm._v(_vm._s(item.contactAddress))]) : _vm._e(), _vm.language == 2 ? _c("span", [_vm._v(_vm._s(item.contactCityEn)), _c("br"), _vm._v(_vm._s(item.contactAddressEn))]) : _vm._e(), _vm.language == 3 ? _c("span", [_vm._v(_vm._s(item.contactCityTr)), _c("br"), _vm._v(_vm._s(item.contactAddressTr))]) : _vm._e()]);
  }), 0)]), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.current == 4,
      expression: "current==4"
    }]
  }, [_vm.language == 1 ? _c("p", {
    staticClass: "pTitle"
  }, [_vm._v(_vm._s(_vm.societyList.contactTitle))]) : _vm._e(), _vm.language == 2 ? _c("p", {
    staticClass: "pTitle"
  }, [_vm._v(_vm._s(_vm.societyList.contactTitleEn))]) : _vm._e(), _vm.language == 3 ? _c("p", {
    staticClass: "pTitle"
  }, [_vm._v(_vm._s(_vm.societyList.contactTitleTr))]) : _vm._e(), _vm.language == 1 ? _c("p", {
    staticClass: "pCon"
  }, [_vm._v(_vm._s(_vm.societyList.contactDesc))]) : _vm._e(), _vm.language == 2 ? _c("p", {
    staticClass: "pCon"
  }, [_vm._v(_vm._s(_vm.societyList.contactDescEn))]) : _vm._e(), _vm.language == 3 ? _c("p", {
    staticClass: "pCon"
  }, [_vm._v(_vm._s(_vm.societyList.contactDescTr))]) : _vm._e(), _c("ul", [_c("li", [_c("em", {
    staticClass: "el-icon-phone"
  }), _vm.language == 1 ? _c("span", {
    staticStyle: {
      "font-weight": "bolder"
    }
  }, [_vm._v(_vm._s(_vm.societyList.contactTel))]) : _vm._e(), _vm.language == 2 ? _c("span", {
    staticStyle: {
      "font-weight": "bolder"
    }
  }, [_vm._v(_vm._s(_vm.societyList.contactTelEn))]) : _vm._e(), _vm.language == 3 ? _c("span", {
    staticStyle: {
      "font-weight": "bolder"
    }
  }, [_vm._v(_vm._s(_vm.societyList.contactTelTr))]) : _vm._e()]), _c("li", {
    staticStyle: {
      "margin-top": "3em"
    }
  }, [_c("em", {
    staticClass: "el-icon-copy-document"
  }), _vm.language == 1 ? _c("span", {
    staticStyle: {
      "font-weight": "bolder"
    }
  }, [_vm._v(_vm._s(_vm.societyList.contactEmail))]) : _vm._e(), _vm.language == 2 ? _c("span", {
    staticStyle: {
      "font-weight": "bolder"
    }
  }, [_vm._v(_vm._s(_vm.societyList.contactEmailEn))]) : _vm._e(), _vm.language == 3 ? _c("span", {
    staticStyle: {
      "font-weight": "bolder"
    }
  }, [_vm._v(_vm._s(_vm.societyList.contactEmailTr))]) : _vm._e()])])])])])])], 1)])])]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "aboutBannerBox"
  }, [_c("img", {
    staticClass: "aboutBanner",
    attrs: {
      src: require("../assets/banner/about_contact.jpg")
    }
  })]);
}];
render._withStripped = true;
export { render, staticRenderFns };