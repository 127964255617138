import { mapState } from 'vuex';
export default {
  name: "about_contact",
  data() {
    return {
      // tabPosition: 'left',
      allData: [{
        contactA: "120.200204",
        contactB: "30.190904",
        contactType: "杭州地址",
        contactTypeEn: "Hangzhou Address",
        contactTypeTr: "杭州地址",
        contactName: "浙江诺辉健康科技有限公司"
      }, {
        contactA: "116.275579",
        contactB: "40.105111",
        contactType: "北京地址",
        contactTypeEn: "Beijing Address",
        contactTypeTr: "北京地址",
        contactName: "北京诺安检验所"
      }, {
        contactA: "113.536635",
        contactB: "22.73335",
        contactType: "广州地址",
        contactTypeEn: "Guangzhou Address",
        contactTypeTr: "廣州地址",
        contactName: "广州诺辉医学检验实验室有限公司"
      }, {
        contactA: "116.477954",
        contactB: "39.913313",
        contactType: "区域办公室",
        contactTypeEn: "Regional Office",
        contactTypeTr: "區域辦公室",
        contactName: "北京-世茂大厦4层"
      }, {
        contactA: "",
        contactB: "",
        contactType: "社会监督",
        contactTypeEn: "Social Supervision",
        contactTypeTr: "社會監督",
        contactName: ""
      }],
      hzList: {
        contactA: "120.200204",
        contactAddress: "浙江省杭州市滨江区长河街道江二路400号和瑞国际科技广场T1幢13层",
        contactAddressEn: "13 / F, building T1, Herui international science and Technology Plaza, No. 400, jianger Road, Changhe street, Binjiang District, Hangzhou City, Zhejiang Province",
        contactAddressTr: "浙江省杭州市濱江區長河街道江二路400號和瑞國際科技廣場T1幢13層",
        contactB: "30.190904",
        contactName: "浙江诺辉健康科技有限公司",
        contactCz: "0571-86430935",
        contactEmail: "jliu@nhbio.com.cn",
        contactTel: "0571-81107210",
        contactType: "杭州地址",
        contactYb: "310052"
      },
      bjList: {
        contactA: "116.275579",
        contactAddress: "北京市昌平区回龙观镇中关村生命科学园生命园路8号院一区15幢7层",
        contactAddressEn: "7 / F, building 15, zone 1, courtyard 8, shengmingyuan Road, Zhongguancun Life Science Park, Huilongguan town, Changping District, Beijing",
        contactAddressTr: "北京市昌平區回龍觀鎮中關村生命科學園生命園路8號院一區15幢7層",
        contactB: "40.105111",
        contactName: "北京诺安检验所",
        contactCz: "010-80765011",
        contactEmail: "jliu@nhbio.com.cn",
        contactTel: "010-80765011",
        contactType: "北京地址",
        contactYb: "102200"
      },
      gzList: {
        contactA: "113.536635",
        contactAddress: "广东省广州市南沙区珠江街道南江二路广东医谷9栋12层",
        contactAddressEn: "12th floor, building 9, Guangdong Medical Valley, Nanjiang 2nd Road, Zhujiang street, Nansha District, Guangzhou City, Guangdong Province",
        contactAddressTr: "廣東省廣州市南沙區珠江街道南江二路廣東醫穀9棟12層",
        contactB: "22.73335",
        contactName: "广州诺辉医学检验实验室有限公司",
        contactCz: "020-22210866",
        contactEmail: "jliu@nhbio.com.cn",
        contactTel: "020-22210866",
        contactType: "广州地址",
        contactYb: "528441"
      },
      officeList: [{
        contactA: "116.477954",
        contactB: "39.913313",
        contactCity: "北京",
        contactCityEn: "Beijing",
        contactCityTr: "北京",
        contactAddress: "北京市朝阳区建国路甲92号世茂大厦4层128室",
        contactAddressEn: "Room 128, 4th floor, Shimao building, No. a 92, Jianguo Road, Chaoyang District, Beijing",
        contactAddressTr: "北京市朝陽區建國路甲92號世茂塔樓4層128室",
        contactName: "北京-世茂大厦4层"
      }, {
        contactA: "121.444307",
        contactB: "31.200662",
        contactCity: "上海",
        contactCityEn: "Shanghai",
        contactCityTr: "上海",
        contactAddress: "上海市徐汇区虹桥路3号港汇中心2座15楼1506室",
        contactAddressEn: "Room 1506, 15 / F, block 2, ganghui center, No. 3, Hongqiao Road, Xuhui District, Shanghai",
        contactAddressTr: "上海市徐匯區虹橋路3號港匯中心2座15樓1506室",
        contactName: "上海-港汇中心2座"
      }, {
        contactA: "118.786991",
        contactB: "32.047552",
        contactCity: "南京",
        contactCityEn: "Nanjing",
        contactCityTr: "南京",
        contactAddress: "江苏省南京市秦淮区汉中路89号金鹰国际中心A座8楼162室",
        contactAddressEn: "Room 162, 8 / F, tower a, Jinying International Center, No. 89 Hanzhong Road, Qinhuai District, Nanjing, Jiangsu",
        contactAddressTr: "江蘇省南京市秦淮區漢中路89號金鷹國際中心A座8樓162室",
        contactName: "南京-金鹰国际中心A座"
      }, {
        contactA: "104.091343",
        contactB: "30.655859",
        contactCity: "成都",
        contactCityEn: "Chengdu",
        contactCityTr: "成都",
        contactAddress: "四川省成都市锦江区合江亭街道下东大街段199号睿东中心41层100室",
        contactAddressEn: "Room 100, 41st floor, Ruidong center, No. 199, Xiadong Street section, Hejiangting street, Jinjiang District, Chengdu, Sichuan",
        contactAddressTr: "四川省成都市錦江區合江亭街道下東大街段199號睿東中心41層100室",
        contactName: "成都-睿东中心B座"
      }, {
        contactA: "113.274445",
        contactB: "23.131729",
        contactCity: "广州",
        contactCityEn: "Guangzhou",
        contactCityTr: "廣州",
        contactAddress: "广东省广州市越秀区北京街道中山五路33号大马站商业中心5层105室",
        contactAddressEn: "Room 105, 5th floor, Damazhan commercial center, No. 33, Zhongshan 5th Road, Beijing street, Yuexiu District, Guangzhou City, Guangdong Province",
        contactAddressTr: "廣東省廣州市越秀區北京街道中山五路33號大馬站商業中心5層105室",
        contactName: "广州-大马站商业中心"
      }],
      societyList: {
        contactTitle: "尊敬的合作伙伴：",
        contactTitleEn: "Dear partner：",
        contactTitleTr: "尊敬的合作夥伴",
        contactDesc: "如您在与诺辉合作中，发现诺辉员工存在舞弊、贿赂、信息泄露、侵占资产等现象，欢迎及时与我们取得联系，感谢您的监督与举报！",
        contactDescEn: "If you find that there are fraud, bribery, information leakage, misappropriation of assets and other phenomena in your cooperation with nuohui, you are welcome to contact us in time. Thank you for your supervision and reporting!",
        contactDescTr: "如您在與諾輝合作中，發現諾輝員工存在舞弊、賄賂、資訊洩露、侵佔資產等現象，歡迎及時與我們取得聯系，感謝您的監督與舉報！",
        contactTel: "合规建议专线：（0571）88022307",
        contactTelEn: "Compliance advice hotline：（0571）88022307",
        contactTelTr: "合規建議專線：（0571）88022307",
        contactEmail: "合规建议邮箱：compliance@nhbio.com.cn",
        contactEmailEn: "Compliance advice  email：compliance@nhbio.com.cn",
        contactEmailTr: "合規建議郵箱：compliance@nhbio.com.cn"
      },
      a: '120.200204',
      b: '30.190904',
      c: '浙江诺辉健康科技有限公司',
      current: 0,
      currentLi: 0,
      map: null
    };
  },
  mounted() {},
  computed: {
    ...mapState({
      language: state => state.language
    })
  },
  watch: {
    a: {
      handler: function () {
        this.init({
          BMap
        });
      },
      deep: true
    }
  },
  created() {
    this.getData();
  },
  methods: {
    showDt(index, event) {
      this.current = index;
      var el = event.target;
      this.a = el.getAttribute('data-lng');
      this.b = el.getAttribute('data-lat');
      this.c = el.getAttribute('data-name');
    },
    showLi(index, event) {
      event.stopPropagation();
      this.currentLi = index;
      var el = event.target;
      console.log(el);
      this.a = el.getAttribute('data-lng');
      console.log(el.getAttribute('data-lng'));
      console.log("a" + this.a + "b" + this.b);
      this.b = el.getAttribute('data-lat');
      this.c = el.getAttribute('data-name');
    },
    init({
      BMap
    }) {
      this.map = new BMap.Map('map');
      // 初始化地图,设置中心点坐标
      var point = new BMap.Point(this.a, this.b);
      this.map.centerAndZoom(point, 18);
      // this.setMarker();
      // 添加鼠标滚动缩放
      // map.enableScrollWheelZoom();
      var marker = new BMap.Marker(point);
      var opts = {
        position: point,
        offset: new BMap.Size(20, -20)
      };
      var label = new BMap.Label(this.c, opts);
      marker.setLabel(label);
      this.map.addOverlay(marker);
    },
    getData() {
      // this.$http.post(this.$api.contact_hzList).then(res => {
      // 	if (res.code == 0) {
      // 		if (res.data) {
      // 			this.hzList = res.data[0];
      // 			this.allData.push(res.data[0])
      // 			// (this.allData)[0]=res.data[0]
      // 		}
      // 	}

      // })
      // this.$http.post(this.$api.contact_bjList).then(res => {
      // 	if (res.code == 0) {
      // 		if (res.data) {
      // 			this.bjList = res.data[0];
      // 			this.allData.push(res.data[0])
      // 			// (this.allData)[1]=res.data[0]
      // 		}
      // 	}

      // })
      // this.$http.post(this.$api.contact_officeList).then(res => {
      // 	if (res.code == 0) {
      // 		if (res.data) {
      // 			this.officeList = res.data;
      // 			this.allData.push(res.data[0])
      // 			// (this.allData)[2]=res.data[0]
      // 		}
      // 	}

      // })
      // this.$http.post(this.$api.contact_societyList).then(res => {
      // 	if (res.code == 0) {
      // 		if (res.data) {
      // 			this.societyList = res.data[0];
      // 			this.allData.push(res.data[0])
      // 			// (this.allData)[3]=res.data[0]
      // 		}
      // 	}

      // })
    }
  }
};